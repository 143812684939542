<template>
    <div class="position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center" style="top: 0;">
        <h1>404 Page not found</h1>
        <small>It seems you're lost, the page you are trying to access might be broken or removed.</small>
        <a href="/" class="btn btn-danger mt-3">Back to Home</a>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>